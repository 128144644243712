<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6" lg="4" xs="12" style="text-align: center">
          <svg
            version="1.1"
            id="logo-side"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 703.2 167.7"
            xml:space="preserve"
          >
            <path
              d="M33.9,45.9l2.2,10.7c6.5-9.1,15.2-13,26.1-13c9.3,0,17,2.8,23.9,8.9L74.3,75c-4.7-3.4-9.7-4.9-15.6-4.9 c-12.3,0-22.7,7.9-22.7,22.5v50.8H7.4V45.9H33.9z"
              class="st0"
            ></path>
            <path
              d="M110,103.8c1.4,9.5,10.5,17.2,26.5,17.2c7.7,0,18.4-1.8,25.3-7.7l16,17.8c-10.5,10.3-27.3,14.4-41.9,14.4c-35.4,0-54.8-20.8-54.8-52c0-29.7,20-50.4,51.8-50.4c33.2,0,55,20.8,48.8,60.7H110z M156.3,83.1c-0.6-10.7-11.5-15.6-23.3-15.6 c-11.5,0-20.2,5.9-22.9,15.6H156.3z"
              class="st0"
            ></path>
            <path
              d="M260.8,143.4V91.8c0-15-6.5-22.7-20.2-22.7c-13.2-0.2-22.1,11.3-22.1,23.9v50.4h-28.1V45.7h25.7l1,12.5c8.7-11.3,19.8-15,31.6-14.8c21.9,0,40.7,10.3,40.7,48.2v51.8H260.8z"
              class="st0"
            ></path>
            <path
              d="M340.1,19v27.3h23l1.7,23.7h-24.9v36.8c0,10.3,5.7,13.1,11.1,13c7.6-0.1,11.8-1.5,14.9-3.1l3.4,24.4c-7.1,2.8-12.9,4-20.6,4.4c-21.2,1-37.2-8.3-37.2-38.7V70h-17.4V46.3h17.4V22.2L340.1,19z"
              class="st0"
            ></path>
            <path
              d="M411.4,24.5c0,23.7-36,23.7-36,0S411.4,0.9,411.4,24.5z"
              class="st1"
            ></path>
            <polygon
              points="373.2,56.5 379.4,160.9 407.3,160.9 413.5,56.5"
              class="st1"
            ></polygon>
            <path
              d="M642.9,45.4l2.2,10.8C651.7,47,660.5,43,671.6,43c9.4,0,17.2,2.8,24.2,9l-12,22.8c-4.8-3.4-9.8-5-15.8-5 c-12.4,0-23,8-23,22.8v51.5h-28.8V45.4H642.9z"
              class="st0"
            ></path>
            <path
              d="M580.2,45.2l-1,11.4C574,47.8,562.4,43,550,43c-23.9-0.3-43.4,12.5-49.7,35.2h91.2l-16.7,6.8l-76.4,2.9 c-0.1,0.9-0.2,1.7-0.2,2.6h76l-16.7,6.8l-59.3,2.3c0.1,1.1,0.2,2.2,0.3,3.2h58.6l-16.7,6.8l-40.5,1.5c6,22.6,24.7,35.6,49,35.4 c9.6-0.2,23.8-4.2,30.4-14.6l1.6,12.4h26c0-32.8,0-66.1,0-98.9H580.2z"
              class="st0"
            ></path>
            <path
              d="M491.5,114c-5,3.7-12.4,5.7-18,5.7c-13.4,0-25.4-9-25.4-24.8c0-16.4,11.6-25.8,26-25.8c5.7,0,12,1.1,17.8,5.3 c3-8.3,7.9-15.1,14.2-20.2c-9.4-8.4-20-11.1-33.2-11.1C444.1,43,419,61.6,419,94.8c0,35.7,25.2,51.5,54.1,51.5 c10.7,0,22.6-2.6,33.2-10.6C499.6,130.4,494.5,123,491.5,114z"
              class="st0"
            ></path>
          </svg>

          <v-img
            class="mt-3"
            v-if="browserLanguage == 'tr'"
            contain
            src="/img/htyc.png"
            height="20"
          />
          <v-form
            v-model="isValidEmail"
            @submit.prevent="sendOtp()"
            v-if="!isOtpSent && isLoginForm"
          >
            <v-text-field
              dense
              class="mt-10 text-field-transparent"
              solo
              flat
              autofocus
              id="email"
              type="email"
              ref="email"
              :rules="[(v) => !!v, (v) => /.+@.+\..+/.test(v)]"
              :placeholder="languages.email[browserLanguage]"
              v-model="loginForm.email"
            />
            <v-text-field
              dense
              class="text-field-transparent"
              solo
              flat
              id="password"
              type="password"
              ref="password"
              :placeholder="languages.password[browserLanguage]"
              v-model="loginForm.password"
              :class="{ shake: loginFormErrors.password.shake }"
              :invalid-feedback="loginFormErrors.password.text"
              :is-valid="loginFormErrors.password.valid"
              @input="validInput($event, loginFormErrors.password)"
            />

            <CRow>
              <CCol class="text-left">
                <v-btn
                  block
                  id="btnLogin"
                  color="danger"
                  class="px-4"
                  :disabled="!isValidEmail || isSubmitting"
                  type="submit"
                  style="font-weight: 900"
                  ><strong>
                    {{ languages.next[browserLanguage] }}
                  </strong></v-btn
                >
              </CCol>
            </CRow>
            <CRow v-if="isPartner">
              <CCol class="text-left">
                <v-btn
                  block
                  id="btnLogin"
                  class="px-4 text-capitalize"
                  @click="clickForgotPasswordForm"
                  text
                  style="font-weight: 900; color: white"
                  ><strong>
                    {{ languages.forgot_password[browserLanguage] }}
                  </strong></v-btn
                >
              </CCol>
            </CRow>
          </v-form>
          <v-form v-else-if="forgotPasswordModal">
            <div v-if="!isForgotPasswordOtpSent">
              <v-text-field
                type="text"
                class="text-field-transparent mt-8"
                v-model="forgotPasswordForm.email"
                solo
                flat
                dense
                label="Email"
              />
              <v-col class="mt-n4">
                <v-btn
                  @click="forgotPassword"
                  block
                  id="btnLogin"
                  color="danger"
                  class="px-4 text-capitalize"
                  :disabled="!forgotPasswordForm.email"
                >
                  {{ languages.send_code[browserLanguage] }}
                </v-btn>
              </v-col>
              <CRow v-if="true">
                <CCol class="text-left">
                  <v-btn
                    block
                    id="btnLogin"
                    class="px-4 text-capitalize"
                    @click="clickLoginForm"
                    text
                    style="font-weight: 900; color: white"
                    ><strong>
                      {{ languages.login[browserLanguage] }}
                    </strong></v-btn
                  >
                </CCol>
              </CRow>
            </div>
            <div v-else-if="newPasswordModal">
              <v-col class="mt-4">
                <v-text-field
                  type="password"
                  v-model="forgotPasswordForm.password"
                  class="text-field-transparent mt-8"
                  solo
                  dense
                  label="Yeni Şifre"
                />

                <v-text-field
                  type="password"
                  v-model="forgotPasswordForm.passwordAgain"
                  class="text-field-transparent"
                  solo
                  dense
                  label="Yeni Şifre (Tekrar)"
                />
              </v-col>
              <v-col class="d-flex justify-center mt-n4">
                <v-btn
                  @click="newPassword"
                  color="primary"
                  class="text-capitalize"
                  :disabled="
                    !forgotPasswordForm.password &&
                    !forgotPasswordForm.passwordAgain
                  "
                >
                  {{ languages.change_password[browserLanguage] }}
                </v-btn>
              </v-col>
            </div>

            <v-form
              v-model="isValidForgotPasswordOtp"
              @submit.prevent="checkForgotPasswordOtp()"
              v-else
            >
              <v-otp-input
                class="mt-8 mb-4"
                v-model="otp"
                type="tel"
                :rules="[
                  (v) => !!v || 'Otp is required',
                  (v) => v.length == 6 || 'Otp must be 6 digit',
                ]"
                variant="solo"
              ></v-otp-input>

              <CRow>
                <CCol class="text-left">
                  <v-btn
                    block
                    id="btnLogin"
                    color="danger"
                    class="px-4"
                    :disabled="!isValidForgotPasswordOtp"
                    type="submit"
                    style="font-weright: 900"
                    ><strong>
                      {{ languages.next[browserLanguage] }}
                    </strong></v-btn
                  >
                  <v-btn
                    block
                    id="btnLogin"
                    color="black"
                    plain
                    class="mt-2"
                    @click="forgotPassword()"
                    style="font-weight: 900"
                    ><strong>
                      {{ languages.send_code_again[browserLanguage] }}
                    </strong></v-btn
                  >
                </CCol>
              </CRow>
            </v-form>
          </v-form>

          <v-form v-model="isValidOtp" @submit.prevent="checkOtp()" v-else>
            <v-otp-input
              class="mt-8 mb-4"
              v-model="otp"
              type="tel"
              :rules="[
                (v) => !!v || 'Otp is required',
                (v) => v.length == 6 || 'Otp must be 6 digit',
              ]"
              variant="solo"
            ></v-otp-input>
            <CRow>
              <CCol class="text-left">
                <v-btn
                  block
                  id="btnLogin"
                  color="danger"
                  class="px-4"
                  :disabled="!isValidOtp"
                  type="submit"
                  style="font-weight: 900"
                  ><strong>
                    {{ languages.login[browserLanguage] }}
                  </strong></v-btn
                >
                <v-btn
                  block
                  id="btnLogin"
                  color="grey"
                  plain
                  class="mt-2"
                  @click="sendOtp()"
                  style="font-weight: 900"
                  ><strong>
                    {{ languages.send_code_again[browserLanguage] }}
                  </strong></v-btn
                >
              </CCol>
            </CRow>
          </v-form>

          <!-- 
            :class="{ shake: loginFormErrors.email.shake }"
            :invalid-feedback="loginFormErrors.email.text"
            :is-valid="loginFormErrors.email.valid"
            @input="validInput($event, loginFormErrors.email)"

          <v-text-field
            dense
            class="text-field-transparent"
            solo
            flat
            id="password"
            type="password"
            ref="password"
            :placeholder="languages.password[browserLanguage]"
            v-model="loginForm.password"
            :class="{ shake: loginFormErrors.password.shake }"
            :invalid-feedback="loginFormErrors.password.text"
            :is-valid="loginFormErrors.password.valid"
            @input="validInput($event, loginFormErrors.password)"
          /> -->
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  name: "Login",
  mounted() {
    this.isPartner = window.location.hostname.includes("partner") || false;

    // // Sayfa açıldığında kullanıcı adına focus yaptık
    // document.getElementById("email").focus();
    // document
    //   .getElementById("email")
    //   .addEventListener("keyup", function (event) {
    //     // Basılan tuş'un keyCode değeri 13 (Yani enter) ise
    //     if (event.keyCode === 13) {
    //       event.preventDefault();
    //       // password input'a focus oluyoruz.
    //       document.getElementById("password").focus();
    //     }
    //   });
    // document
    //   .getElementById("password")
    //   .addEventListener("keyup", function (event) {
    //     // Basılan tuş'un keyCode değeri 13 (Yani enter) ise
    //     if (event.keyCode === 13) {
    //       event.preventDefault();
    //       // Login button'un click özelliğini tetikliyoruz.
    //       document.getElementById("btnLogin").click();
    //     }
    //   });
  },
  data: () => {
    return {
      isValidEmail: false,
      isValidOtp: false,
      isOtpSent: false,
      isLoginForm: true,
      isSubmitting: false,
      browserLanguage: navigator?.language.includes("tr") ? "tr" : "en",
      languages: {
        email: {
          tr: "E-Posta Adresi",
          en: "Email Address",
        },
        password: {
          tr: "Şifre",
          en: "Password",
        },
        next: {
          tr: "Devam Et",
          en: "Next",
        },
        login: {
          tr: "GİRİŞ YAP",
          en: "Log In",
        },
        min: {
          tr: "Bu alana en az ",
          en: "Minimum ",
        },
        max: {
          tr: ", en fazla ",
          en: "maximum ",
        },
        entry: {
          tr: " karakter girilebilir.",
          en: " characters can be entered in this field!",
        },
        empty: {
          tr: "Bu alan boş bırakılamaz",
          en: "This field cannot be left blank",
        },
        error: {
          tr: "Bir Sorun Oluştu",
          en: "There is a problem, please contact renticar.",
        },
        forgot_password: {
          tr: "Şifremi Unuttum",
          en: "Forgot Password",
        },
        change_password: {
          tr: "Şifremi Değiştir",
          en: "Change Password",
        },
        send_code_again: {
          tr: "Tekrar Kod Gönder",
          en: "Send Code Again",
        },
        send_code: {
          tr: "Doğrulama Kodu Gönder",
          en: "Send Verification Code",
        },
      },
      // LOGIN FORM
      loginBtnDisable: false,
      loginFormErrors: {
        email: {
          valid: null,
          text: "",
          shake: false,
          min: 3,
          max: 80,
        },
        password: {
          valid: null,
          text: "",
          shake: false,
          min: 6,
          max: 25,
        },
      },
      otp: "",
      loginForm: {
        email: "",
        password: "",
      },
      isPartner: false,
      forgotPasswordModal: false,
      isForgotPasswordOtpSent: false,
      isValidForgotPasswordOtp: false,
      forgotPasswordForm: {
        email: "",
        password: "",
        passwordAgain: "",
      },
      newPasswordModal: false,
    };
  },
  methods: {
    async checkOtp() {
      try {
        if (!this.isValidOtp) {
          return;
        }
        let response = await axios.post(
          process.env.VUE_APP_API_URL + "admin/signin-check-otp",
          {
            email: this.loginForm.email,
            password: this.loginForm.password,
            otp: this.otp,
          },
          {
            withCredentials: true,
            headers: {
              lang: this.browserLanguage,
            },
          }
        );
        if (response.data.result == "success") {
          this.isSubmitting = true;
          const { defaultCurrency, defaultLang } = response.data.user;
          this.$store.commit("setLocale", defaultLang || "tr");
          this.$store.commit("setCurrency", defaultCurrency || "TRY");
          response && this.$router.push("/dashboard");
        }
      } catch (error) {
        let message = "";
        if (error.message == "Network Error") message = "Sunucu hatası!";
        if (error.response)
          message = error.response?.data?.errors[0]?.message || message;
        else {
          message = "Bilinmedik Bir Hata Oluştu";
        }
        this.localSwal(message);
      } finally {
        this.loginBtnDisable = false;
      }
    },
    async checkForgotPasswordOtp() {
      try {
        console.log("adfasdf");
        if (!this.isValidForgotPasswordOtp) {
          return;
        }
        let response = await axios.post(
          process.env.VUE_APP_API_URL + "admin/forgot-password/check-otp",
          {
            email: this.forgotPasswordForm.email,
            otp: this.otp,
          },
          {
            withCredentials: true,
            headers: {
              lang: this.browserLanguage,
            },
          }
        );
        if (response.data.result == "success") {
          this.isSubmitting = true;
          this.newPasswordModal = true;
          this.isForgotPasswordOtpSent = true;
          console.log("tuuuruuuuuuuuuu");
        }
      } catch (error) {
        let message = "";
        if (error.message == "Network Error") message = "Sunucu hatası!";
        if (error.response)
          message = error.response?.data?.errors[0]?.message || message;
        else {
          message = "Bilinmedik Bir Hata Oluştu";
        }
        this.localSwal(message);
      } finally {
        this.loginBtnDisable = false;
      }
    },
    async newPassword() {
      try {
        if (
          this.forgotPasswordForm.password !==
          this.forgotPasswordForm.passwordAgain
        ) {
          return Vue.swal({
            icon: "error",
            title: "Şifre Değiştir",
            html: "Girilen Şifreler Aynı Olmalı",
            confirmButtonText: "Tamam",
          });
        }
        axios
          .put(
            process.env.VUE_APP_API_URL +
              "admin/forgot-password/reset-password/",
            {
              email: this.forgotPasswordForm.email,
              password: this.forgotPasswordForm.password,
            },
            { withCredentials: true }
          )
          .then((response) => {
            if (response.data.result === "success") {
              this.forgotPasswordModal = false;
              this.isSubmitting = false;
              this.isLoginForm = true;
              Vue.swal({
                icon: "success",
                title: "Şifreniz başarıyla değişirildi.",
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Vue.swal.stopTimer);
                  toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
                },
              });
            } else {
              switch (response.data.errCode) {
                case "COULD_NOT_CHANGE_PASSWORD":
                  Vue.swal({
                    icon: "error",
                    title: "Şifre Değiştir",
                    html: "Şifre değiştirilemedi!",
                    confirmButtonText: "Tamam",
                  });
                  break;
                case "ACCOUNT_NOT_FOUND":
                  Vue.swal({
                    icon: "error",
                    title: "Şifre Değiştir",
                    html: "Hesap bulunamadı!",
                    confirmButtonText: "Tamam",
                  });
                  break;
                case "OLD_PASSWORD_IS_INCORRECT":
                  Vue.swal({
                    icon: "error",
                    title: "Şifre Değiştir",
                    html: "Eski şifre hatalı! Lütfen eski şifrenizi kontrol edin.",
                    confirmButtonText: "Tamam",
                  });
                  break;
              }
            }
          });
      } catch (error) {
        let message = "";
        if (error.message == "Network Error") message = "Sunucu hatası!";
        if (error.response)
          message = error.response?.data?.errors[0]?.message || message;
        else {
          message = "Bilinmedik Bir Hata Oluştu";
        }
        this.localSwal(message);
      } finally {
        this.loginBtnDisable = false;
      }
    },
    async sendOtp() {
      try {
        if (!this.isValidEmail) {
          return;
        }
        this.otp = "";
        let response = await axios.post(
          process.env.VUE_APP_API_URL + "admin/signin-send-otp",
          this.loginForm,
          {
            withCredentials: true,
            headers: {
              lang: this.browserLanguage,
            },
          }
        );
        if (response.data.result == "success") {
          this.isOtpSent = true;
          this.$store.dispatch({
            type: "successSwal",
            text: "Onay Kodunuz Email Hesabınıza Başarılı Bir Şekilde Gönderildi",
            refresh: false,
          });
        }

        console.log(response);
      } catch (error) {
        let message = "";
        console.log("dasfasdf", message);
        console.log("dasfasdf", error.response.data.errors[0].message);

        if (error.message == "Network Error") message = "Sunucu hatası!";
        if (error.response)
          message = error.response?.data?.errors[0]?.message || message;

        this.localSwal(message);
      }
    },
    async forgotPassword() {
      try {
        this.otp = "";
        let response = await axios.post(
          process.env.VUE_APP_API_URL + "admin/signin-forgot-password",
          this.forgotPasswordForm,
          {
            withCredentials: true,
            headers: {
              lang: this.browserLanguage,
            },
          }
        );
        if (response.data.result == "success") {
          this.isForgotPasswordOtpSent = true;
          this.$store.dispatch({
            type: "successSwal",
            text: "Onay Kodunuz Email Hesabınıza Başarılı Bir Şekilde Gönderildi",
            refresh: false,
          });
        }
      } catch (error) {
        let message = "";

        this.forgotPasswordForm.email = "";
        if (error.message == "Network Error") message = "Sunucu hatası!";
        if (error.response)
          message = error.response?.data?.errors[0]?.message || message;

        this.localSwal(message);
      }
    },
    validInput(val, field) {
      if (val) {
        switch (field) {
          case this.loginFormErrors.email:
            if (val.length < field.min || val.length > field.max) {
              field.text =
                this.languages.min[this.browserLanguage] +
                field.min +
                this.languages.max[this.browserLanguage] +
                field.max +
                this.languages.entry[this.browserLanguage];
              field.valid = false;
            } else {
              field.text = "";
              field.valid = true;
            }
            break;
          case this.loginFormErrors.password:
            if (val.length < field.min || val.length > field.max) {
              field.text =
                this.languages.min[this.browserLanguage] +
                field.min +
                this.languages.max[this.browserLanguage] +
                field.max +
                this.languages.entry[this.browserLanguage];
              field.valid = false;
            } else if (val.match(/\s/g)) {
              field.text = this.languages.empty[this.browserLanguage];
              field.valid = false;
            } else {
              field.text = "";
              field.valid = true;
            }
            break;
        }
      } else {
        field.text = this.languages.empty[this.browserLanguage];
        field.valid = false;
      }
    },
    async login() {
      try {
        // state içerisinde tutulan inputların invalid kontrölü ve teker teker error handle edilmesi
        Object.keys(this.loginFormErrors).forEach(
          (input) => (
            this.validInput(this.loginForm[input], this.loginFormErrors[input]),
            this.validWrite(input)
          )
        );

        //gönderilen 2 değer de yok ise login function'undan çıkar
        const inputValues = ["email", "password"];
        const isFormInvalid = inputValues
          .map((inputValue) => !this.loginFormErrors[inputValue].valid)
          .includes(false);

        // eğer form invalid değilse çıkart
        if (!isFormInvalid) return;

        //progress start
        this.$Progress.start();

        //giriş yap buttonunu disable true yap
        this.loginBtnDisable = true;

        //body oluştur
        const body = this.loginForm;

        //options
        const options = {
          withCredentials: true,
          headers: {
            lang: this.browserLanguage,
          },
        };

        //axios process
        let response = await axios.post(
          process.env.VUE_APP_API_URL + "admin/signin",
          body,
          options
        );
        const { defaultCurrency, defaultLang } = response.data.user;
        this.$store.commit("setLocale", defaultLang || "tr");
        this.$store.commit("setCurrency", defaultCurrency || "TRY");
        response && this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        //error tipine göre message içerisindeki default stringi değiştirip ona göre localSwal çalıştırılıyor
        let message = this.languages.error[this.browserLanguage];
        if (error.message == "Network Error") message = "Sunucu hatası!";
        if (error.response)
          message = error.response?.data?.errors[0]?.message || message;
        this.localSwal(message);
      } finally {
        //finally durumunda progress durduruluyor
        this.$Progress.finish();

        //her ihtimale karşı ilk başta disable verilen button false olarak set ediliyor
        this.loginBtnDisable = false;
      }
    },
    localSwal(
      text = this.languages.error[this.browserLanguage],
      icon = "error",
      props
    ) {
      Vue.swal.fire({
        ...props,
        icon,
        showCloseButton: true,
        text,
      });
    },

    validWrite(input) {
      if (!this.loginFormErrors[input].valid) {
        this.loginFormErrors[input].shake = true;
        setTimeout(() => {
          this.loginFormErrors[input].shake = false;
        }, 1000);
      }
    },
    clickLoginForm() {
      this.forgotPasswordModal = false;
      this.isLoginForm = true;
      this.loginForm.email = "";
      this.loginForm.password = "";
    },
    clickForgotPasswordForm() {
      this.forgotPasswordModal = true;
      this.isLoginForm = false;
      this.forgotPasswordForm.email = "";
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.c-app {
  background-image: url("../../../public/img/bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

#logo-side {
  width: 175px;
  height: 41px;

  .st0 {
    fill: #fff;
  }

  .st1 {
    fill: #e1251b;
  }
}
</style>
